.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.auth {
    background-position: top center;
    background-repeat: no-repeat;
    background-clip: border-box;
    background-color: rgb(35,31,32);
    background-size: cover;
    background-image: url(/static/media/header.bfcd0d09.png);
}


@font-face {
  font-family: 'FrutigerLTStd-Roman';
  src: url(/static/media/FrutigerLTStd-Roman.c2831f6c.eot);
  src: local('FrutigerLTStd-Roman'),
  url(/static/media/FrutigerLTStd-Roman.c2831f6c.eot?#iefix) format('embedded-opentype'),
  url(/static/media/FrutigerLTStd-Roman.7bb458fd.woff2) format('woff2'),
  url(/static/media/FrutigerLTStd-Roman.8130b3fa.woff) format('woff'),
  url(/static/media/FrutigerLTStd-Roman.5c798edc.ttf) format('truetype'),
  url(/static/media/FrutigerLTStd-Roman.be316b3e.svg#FrutigerLTStd-Roman) format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'GothamBlack';
  src: url(/static/media/GothamBlack.cb08c1d8.eot);
  src: local('Gotham Black'), local('GothamBlack'),
  url(/static/media/GothamBlack.cb08c1d8.eot?#iefix) format('embedded-opentype'),
  url(/static/media/GothamBlack.15ab757a.woff2) format('woff2'),
  url(/static/media/GothamBlack.340b137e.woff) format('woff'),
  url(/static/media/GothamBlack.a2297095.ttf) format('truetype'),
  url(/static/media/GothamBlack.ee653a7f.svg#GothamBlack) format('svg');
  font-weight: 900;
  font-style: normal;
}

body {
  margin: 0;
  padding: 0;
  font-family: "FrutigerLT-Roman",sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #231f20;
}

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6, .navbar-brand {
  font-family: GothamBlack,sans-serif;
  font-weight:normal;
}

@-webkit-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

@keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

body, html, #root, #root .app {
  height: 100%;
}

table th.sortable {
  cursor: pointer;
}
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box,
.react-datepicker__time-container,
.react-datepicker__time-box {
  width: 100px;
}

.ck-editor__editable_inline {
  min-height: 300px;
}
